import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/PostCard";

import { PostInfo } from "../fragments/PostInfo";

export default function Blog({data}) {
  return (
    <Layout>
      <div className="page-blog">
        <h1>
          Blog
        </h1>
        {data.writing.nodes.map((item, i) => {
          return (
            <PostCard 
              key={i}
              post={item}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
query travelsQuery {
  writing: allMdx(
    filter: {fields: {sourceName: {eq: "writing"}}},
    sort:{fields:[frontmatter___date], order:DESC}
    ){
   nodes {
      ...PostInfo
    }
  }
  travels: allMdx(
    filter: {fields: {sourceName: {eq: "travels"}}},
    sort:{fields:[frontmatter___date], order:DESC}
    ){
   nodes {
      ...PostInfo
    }
  }
}
`